import React from "react";
import { get } from "lodash";

import {
  dossierPageWrapper as coreDossierPageWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";

import dossierDefinitionKeys from "../../constants/dossierDefinitionKeys";
import VekaRentesubsidieDossier from "./vekaRentesubsidieDossier";
import VekaCSVDossier from "./vekaCSVDossier";
import VekaBetCSVDossier from "./vekaBetCSVDossier";
import VekaRechtBetCSVDossier from "./vekaRechtBetCSVDossier";

import contextType from "@skryv/core-react/src/services/contextTypes";
import { DossierPage as CoreDossierPage } from "@skryv/core-react-vo/src/components/dossier/DossierPage/DossierPage";

export function vekaDossierPageWrapper() {
  class vekaDossierPage extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    render() {
      const dossierDefinitionName = get(
        this.props.dossierDetails,
        ["dossier", "dossierDefinition", "key"],
        ""
      );
      switch (dossierDefinitionName) {
        case dossierDefinitionKeys.RENTESUBSIDIE_CSV:
          return <VekaCSVDossier {...this.props} />;
        case dossierDefinitionKeys.RENTESUBSIDIE_BET_CSV:
          return <VekaBetCSVDossier {...this.props} />;
        case dossierDefinitionKeys.RENTESUBSIDIE:
          return <VekaRentesubsidieDossier {...this.props} />;
        case dossierDefinitionKeys.RENTESUBSIDIE_RECHT_BET_CSV:
          return <VekaRechtBetCSVDossier {...this.props} />;
        default:
          return <CoreDossierPage {...this.props} />;
      }
    }
  }

  return vekaDossierPage;
}

export default coreDossierPageWrapper(vekaDossierPageWrapper());
