export default {
  CSV_OPGELADEN: 'csv_opgeladen',
  RENTESUBSIDIE_DOSSIERS_AANGEMAAKT: 'rentesubsidie_dossiers_aangemaakt',
  CSV_VALIDATIE_OK: 'csv_validatie_ok',
  CSV_VALIDATIE_NOK: 'csv_validatie_nok',
  CSV_VALIDATIE_NOK2: 'csv_validatie_nok2',
  CSV_DOSSIER_GEANNULEERD: 'csv_dossier_geannuleerd',
  DOSSIER_INGEDIEND_BANK: 'dossier_ingediend_bank',
  DOSSIER_INGEDIEND_ENERGIEHUIS: 'dossier_ingediend_energiehuis',
  EPC_CONTROLE_OK: 'epc_controle_ok',
  EPC_CONTROLE_NOK: 'epc_controle_nok',
  DOSSIER_GECONFIRMEERD: 'dossier_geconfirmeerd',
  DOSSIER_GECORRIGEERD: 'dossier_gecorrigeerd',
  DOSSIER_AFGEKEURD: 'dossier_afgekeurd',
  DOSSIER_GOEDGEKEURD: 'dossier_goedgekeurd',
  DOSSIER_HERACTIVEERD: 'dossier_heractiveerd',
  DOSSIER_MANUEEL_GOEDGEKEURD: 'dossier_manueel_goedgekeurd',
  DOSSIER_MANUEEL_AFGEKEURD: 'dossier_manueel_afgekeurd',
  DOSSIER_GEANNULEERD: 'dossier_geannuleerd',
  RENTESUBSIDIE_RECHTZETTING_AANGEVRAAGD: 'rentesubsidie_rechtzetting_aangevraagd',
  RECHTZETTING_GEWEIGERD: 'rechtzetting_geweigerd',
  RECHTZETTING_GOEDGEKEURD: 'rechtzetting_goedgekeurd',
  RECHTZETTING_WIJZIGEN: 'rechtzetting_wijzigen',
  RECHTZETTING_GEWIJZIGD: 'rechtzetting_wijziging_ingediend',
  WACHTEN_OP_MANUELE_CONTROLE_CSV: 'wachten_op_manuele_controle_csv'
};