import React from "react";

import CoreDashboardPage from "@skryv/core-react-vo/src/pages/Dashboard/DashboardPage";

import contactInformation from "../constants/contactInformation";
import dosdefKeys from "../constants/dossierDefinitionKeys";
import { fetchVekaProfile } from "../store/actions";
import { selectProfileIsBankFromVekaProfile } from "../store/selectors";
import { connect } from "react-redux";
import {
  defaultProps,
  externalProps,
  internalProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import contextType from "@skryv/core-react/src/services/contextTypes";

class vekaDashboard extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  constructor(props) {
    super(props);
    this.props.fetchVekaProfile();

    this.state = {
      title: "VEKA ePortaal",
      contactInformation,
      goToDossierInformationPageFirst: true,
    };
  }

  render() {
    return (
      <CoreDashboardPage
        {...this.state}
        {...this.props}
        createDossierInformation={this.props.createDossierInformation}
      ></CoreDashboardPage>
    );
  }
}

const mapStateToProps = (state) => {
  const isBank = selectProfileIsBankFromVekaProfile(state);
  let createDossierInformation = [];
  if (isBank) {
    createDossierInformation = [
      {
        dosdefKey: dosdefKeys.RENTESUBSIDIE_CSV,
        buttonLabels: {
          active: "Indienen",
        },
      },
      {
        dosdefKey: dosdefKeys.RENTESUBSIDIE_BET_CSV,
        buttonLabels: {
          active: "Indienen",
        },
      },
      {
        dosdefKey: dosdefKeys.RENTESUBSIDIE_RECHT_BET_CSV,
        buttonLabels: {
          active: "Indienen",
        },
      },
    ];
  }

  return { createDossierInformation };
};

const mapDispatchToProps = {
  fetchVekaProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(vekaDashboard);
