import React from 'react';
import { get, isEmpty, map } from 'lodash';
import moment from 'moment';

import '@govflanders/vl-ui-core';
import '@govflanders/vl-ui-util';

import contextType from '@skryv/core-react/src/services/contextTypes';
import { dossierPageWrapper, externalProps, internalProps, defaultProps } from '@skryv/core-react/src/components/dossier/DossierPage/DossierPage';

import PageContent from '@skryv/core-react-vo/src/components/layout/Content/Content';
import ActiveTasks from '@skryv/core-react-vo/src/components/dossier/ActiveTasks/ActiveTasks';
import Contact from '@skryv/core-react-vo/src/components/base/Contact/Contact';
import FileDisplay from '@skryv/core-react-vo/src/components/base/FileDisplay/FileDisplay';
import InfoBlock from '@skryv/core-react-vo/src/components/base/InfoBlock/InfoBlock';
import Notification from '@skryv/core-react-vo/src/components/base/Notification/Notification';
import Title from '@skryv/core-react-vo/src/components/base/Title/Title';
import Modal from '@skryv/core-react-vo/src/components/base/Modal/Modal';
import Loader from '../loader/Loader';
import DossierProgress from '@skryv/core-react-vo/src/components/dossier/DossierProgress/DossierProgress';

import contactInformation from '../../constants/contactInformation';

import documentDefinitionKeys from '../../constants/documentDefinitionKeys';
import milestoneDefinitionKeys from '../../constants/milestoneDefinitionKeys';
import taskDefinitionKeys from '../../constants/taskDefinitionKeys';
import { notificationTypes } from '@skryv/core-react/src/components/base/Notification/Notification';

import './vekaCSVDossier.scss';

export class customVekaCSVDossier extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  constructor(props) {
    super(props);

    this.state = {
      shouldShowLoader: false,
      loaderLabel: null,
      notifications: [],
      dossierInformation: [],
      downloads: []
    };

    this.waitForMilestone = this.waitForMilestone.bind(this);
  }

  componentDidMount() {
    this.props.fetchCurrentDossierMilestone(this.props.dossier.id)
      .then(() => {
        this.waitForMilestone();
      });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    if (this.props.dossierDetails !== prevProps.dossierDetails) {
      // the props have just been updated; so either a parent updated the props or new values came in through redux
      this.setState({
        dossierInformation: this.getDossierInformation(),
        downloads: this.getDownloads(),
        notifications: this.getNotifications()
      });
    }
  }

  getDossierInformation() {
    const dossierInformation = [];
    if(this.props.dossier) {
      dossierInformation.push({
        value: get(this.props.dossier,'label'),
        label: this.context.gettext('Dossier label')
      });
      dossierInformation.push({
        value: moment(get(this.props.dossier,'updatedAt')).format('DD-MM-YYYY'),
        label: this.context.gettext('Last update')
      });
    }
    return dossierInformation;
  }

  getDownloads() {
    if (!this.props.dossierDetails) return [];
    const downloads = [];
    const csvDoc = this.props.getDocument(documentDefinitionKeys.RENTESUBSIDIE_BET_CSV_OPLADEN, true);
    if (csvDoc && this.props.getMilestone(milestoneDefinitionKeys.CSV_OPGELADEN)) {
      downloads.push(this.props.mapTaskToViewDownloadInfo(this.props.getTask(taskDefinitionKeys.LAAD_CSV_OP), 'CSVformulier'));
    }
    const feedbackDoc = this.props.getDocument(documentDefinitionKeys.RENTESUBSIDIE_FEEDBACK);
    if(this.props.getMilestone(milestoneDefinitionKeys.CSV_VALIDATIE_NOK) && this.props.getTask(taskDefinitionKeys.LAAD_CSV_OP, true)){
      downloads.push(this.props.mapDocumentToPdfDownloadInfo(feedbackDoc,'CSV Validatie-rapport'));
    }
    if(this.props.getMilestone(milestoneDefinitionKeys.RENTESUBSIDIE_DOSSIERS_AANGEMAAKT)){
      downloads.push(this.props.mapDocumentToPdfDownloadInfo(feedbackDoc,'CSV Betalingencreatie-rapport'));
    }
    return downloads;
  }

  getNotifications() {
    if (!this.props.dossierDetails) return [];
    let notifications = [];
    if(this.props.getMilestone(milestoneDefinitionKeys.CSV_VALIDATIE_NOK) && (this.props.getMilestone(milestoneDefinitionKeys.CSV_VALIDATIE_NOK).timestamp > this.props.getMilestone(milestoneDefinitionKeys.CSV_OPGELADEN).timestamp)){
      notifications = [{
        id: this.props.dossier.id,
        title: this.context.gettext('Errors in opgeladen CSV'),
        message: this.context.gettext('Het opgeladen CSV-bestand bevat errors en de betalingen kunnen niet opgeladen worden (zie CSV Validatie-rapport hieronder). Gelieve na te kijken of het CSV-bestand het juiste dataformaat heeft (.csv) en of het correct is ingevuld. Gelieve een geldig CSV-bestand op te laden.'),
        type: notificationTypes.ERROR
      }];
    }
    if(this.props.getMilestone(milestoneDefinitionKeys.CSV_DOSSIER_GEANNULEERD)){
      notifications = [{
        id: this.props.dossier.id,
        title: this.context.gettext('Rentesubsidie CSV dossier geannuleerd'),
        message: this.context.gettext('Dit CSV-dossier werd geannuleerd en zal niet verder verwerkt worden.'),
        type: notificationTypes.WARNING
      }];
    }
    if(this.props.getMilestone(milestoneDefinitionKeys.RENTESUBSIDIE_DOSSIERS_AANGEMAAKT)){
      notifications = [{
        id: this.props.dossier.id,
        title: this.context.gettext('Rentesubsidie betalingen doorgegeven'),
        message: this.context.gettext('De nieuwe betalingen werden succesvol overgedragen aan VEKA. De lijst van overgedragen betalingen kan geraadpleegd worden in het creatie-rapport hieronder. Betalingen die al eerder werden ingediend (cumul) worden niet overgedragen.'),
        type: notificationTypes.SUCCESS
      }];
    }
    return notifications;
  }

  waitForMilestone() {
    if (!this.props.currentMilestone || this.props.currentMilestone.key === milestoneDefinitionKeys.CSV_VALIDATIE_NOK || this.props.currentMilestone.key === milestoneDefinitionKeys.RENTESUBSIDIE_DOSSIERS_AANGEMAAKT) {
      return;
    }

    this.interval = setInterval(() => {

      this.props.fetchCurrentDossierMilestone(this.props.dossier.id).then(({ api }) => {
        const currentMilestoneKey = api.response.data.key;

        if (currentMilestoneKey === milestoneDefinitionKeys.CSV_OPGELADEN) {
          this.setState({
            shouldShowLoader: true,
            loaderLabel: "Uw opgeladen CSV wordt gevalideerd..."
          });

          return;
        }

        if (currentMilestoneKey === milestoneDefinitionKeys.CSV_VALIDATIE_OK) {
          return this.props.fetchDossierDetails(this.props.dossier.id).then(() => {
            this.setState({
              shouldShowLoader: true,
              loaderLabel: "Het opgeladen CSV-bestand werd succesvol gevalideerd. Dossiercreatie en cumul controle in uitvoering..."
            });
          });
        }

        if (currentMilestoneKey === milestoneDefinitionKeys.CSV_VALIDATIE_NOK || currentMilestoneKey === milestoneDefinitionKeys.RENTESUBSIDIE_DOSSIERS_AANGEMAAKT) {
          return Promise.all([
            this.props.fetchDossierDetails(this.props.dossier.id),
            // TODO: fetch enabled?
            this.props.fetchDossierExecutableTasks(this.props.dossier.id)
          ]).finally(() => {
            this.setState({
              shouldShowLoader: false,
            });
            clearInterval(this.interval);
          });
        }
      }).catch(() => {
        this.setState({
          shouldShowLoader: false
        });
        clearInterval(this.interval);
      });

    }, 2000);
  }

  renderTitle() {
    return (
      <div className="dossier-title vl-col--1-1">
        <Title level={ 1 } title={ this.props.dossier.label } />
      </div>
    );
  }

  renderNotification() {
    const notifications = this.state.notifications;
    return (notifications && notifications.length > 0 &&
      <div className="dossier-notifications vl-col--1-1">
        { map(notifications, (notification) =>
          <Notification notification={ notification } class="dossier-notification" />
        ) }
      </div>
    );
  }

  renderActiveTasks() {
    if (this.props.loadingDossierDetails) {
      return;
    }

    const { requiredTasks } = this.props.getMyActiveDossierTasks();
    return (requiredTasks && requiredTasks.length > 0 &&
      <div className="dossier-active-tasks vl-col--1-1">
        <ActiveTasks activeTasks={ requiredTasks } execute={ (task) => this.props.executeTask(task.id) } />
      </div>
    );
  }

  renderEnabledTasks() {
    const { optionalTasks } = this.props.getMyActiveDossierTasks();

    if (!optionalTasks || isEmpty(optionalTasks)) return null;

    return (
      <div className="dossier-title vl-col--1-1">
        <Title level={ 2 } title="Dossier Acties" iconClass="vl-vi-cursor-hand"/>
        <ActiveTasks activeTasks={ optionalTasks } execute={ this.props.handleEnabledTaskSelect }/>
      </div>
    );
  }

  renderDossierInformation() {
    const dossierInformation = this.state.dossierInformation;
    return (dossierInformation && dossierInformation.length > 0 &&
      <div className="dossier-information vl-col--1-1">
        <section className="vl-infoblock">
          <Title level={ 2 } title={ this.context.gettext('Dossier details') } iconClass="vl-vi-news" />
          <InfoBlock infoBlockData={ dossierInformation } columnsNumber={ 2 } />
        </section>
      </div>
    );
  }

  renderDownloads() {
    const downloads = this.state.downloads;

    return (downloads && downloads.length > 0 &&
      <div className="dossier-downloads vl-col--1-1">
        <section className="vl-infoblock">
          <Title level={ 2 } title={ this.context.gettext('Documents and Downloads') } iconClass="vl-vi-file-download" />
          <FileDisplay downloads={ downloads } />
        </section>
      </div>
    );
  }

  renderContactInformation() {
    return (
      <div className="dossier-contact vl-col--1-1">
        <section className="vl-infoblock">
          <Title level={ 2 } title={ this.context.gettext('Contact') } iconClass="vl-vi-chat" />
          <Contact contactInformation={ contactInformation } />
        </section>
      </div>
    );
  }

  renderActivateTaskWarningModal() {
    return (
      <Modal
        open={ this.props.shouldShowActivateTaskWarningModal }
        text={ this.context.gettext('Are you sure?') }
        title={ `${this.context.gettext('Voer')} ${
          (this.props.modalTask.activityName || '').toLowerCase()
          } ${this.context.gettext('uit')}` }
        yesText={ this.context.gettext('Yes') }
        noText={ this.context.gettext('No') }
        yes={ () => this.props.toEnabledTask(this.props.modalTask.id) }
        no={ () => this.props.cancelActivationOfEnabledTask() }
        modalIdentifier={ this.props.modalTask.id }
      />
    );
  }

  renderLoader() {
    return (
      <Loader
        label={ this.context.gettext(
          this.state.loaderLabel
        ) }
      />
    );
  }

  renderDossierProgress() {
    const steps = this.props.getDossierProgressSteps();
    return steps && <DossierProgress steps={steps} />;
  }

  render() {
    return (
      <PageContent contentName="dossier">
        {this.renderDossierProgress()}
        <div className="dossier-page-header">
          <div className="dossier-page-header-title">
            {this.props.shouldShowTitle && this.renderTitle()}
          </div>
        </div>
        {this.renderActivateTaskWarningModal()}
        {this.state.shouldShowLoader && this.renderLoader() }
        {this.props.shouldShowNotifications && this.renderNotification()}
        {this.props.shouldShowRequiredTasks && this.renderActiveTasks()}
        {this.renderEnabledTasks()}
        {this.props.shouldShowDossierInformation &&
          this.renderDossierInformation()}
        {this.props.shouldShowDownloads && this.renderDownloads()}
        {this.props.shouldShowContactInformation &&
          this.renderContactInformation()}
      </PageContent>
    );
  }
}

export default dossierPageWrapper(customVekaCSVDossier);
